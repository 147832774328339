import React from "react";
import { Link, graphql } from "gatsby";
import Layout from "../../components/layout/layout";

import FilterBar from "../../components/browser/filter-bar";
import PageContainer from "../../components/layout/page-container";
import PageSection from "../../components/layout/page-section";

class ImagesByYearPage extends React.Component {
  constructor({ data }) {
    super();

    this.data = data;
  }

  render() {
    const allYears = this.data.allImagesJson.edges.map(({ node }) => node.year);

    const uniqueYears = allYears.filter((year, idx, self) => {
      return (year !== null) && (self.indexOf(year) === idx);
    });

    return (
      <Layout>
        <PageContainer
          bottomPadding="lg"
        >
          <PageSection background="dark-accent">
            <h1 className="page-title">Images by Year</h1>
          </PageSection>
          <PageSection topPadding="md">
            <FilterBar filterSet="images" currentFilter="year" />
            <ul className="listing-grid">
              {uniqueYears.map(year => (
                <li key={year} className="listing-grid__cell">
                  <Link to={`/images/years/${year}`}>{year}</Link>
                </li>
              ))}
            </ul>
            <FilterBar filterSet="images" currentFilter="year" />
          </PageSection>
        </PageContainer>
      </Layout>
    );
  }
}

export default ImagesByYearPage;

export const query = graphql`
  query ImagesByYearQuery {
    allImagesJson(sort: {fields: year}) {
      edges {
        node {
          year
        }
      }
    }
  }
`